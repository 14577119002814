<template>
<DenseContainer>
    <Considered :active="dialogs.considered"></Considered>
    <SubmitWishes :active="dialogs.submitWishes"></SubmitWishes>
    <v-row>
        <v-col cols="12">
            <v-card>
               <v-list-item>
                    <v-list-item-avatar>
                        <v-img :src="$store.state.auth.profile.picture"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{$store.state.auth.profile.name}} {{$store.state.auth.profile.family_name}}</v-list-item-title>
                        <v-list-item-subtitle>{{$store.state.auth.profile.employee.aid}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-alert elevation="2" dense icon="mdi-information">
                <a class="primaryLight--text text-decoration-underline" @click="dialogs.submitWishes++">Wo kann ich die Geschenke abgeben?</a><br>
                <a class="primaryLight--text text-decoration-underline" @click="dialogs.considered++">Was ist zu beachten?</a>
            </v-alert>
        </v-col>
    </v-row>
    <v-row v-if="Object.keys($store.state.takenWishes).length == 0">
        <v-col cols="12">
            <v-alert elevation="2" dense type="info" color="primary">
                Sie haben bisher noch keine Wünsche ausgewählt, die Sie erfüllen möchten.<br>
                <a class="white--text text-decoration-underline" @click="$router.push('/wishes')">Schauen Sie sich die offenen Wünsche an</a>
            </v-alert>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12">
            <v-subheader class="px-0">Noch nicht erledigte Wünsche</v-subheader>
        </v-col>
        <v-col cols="12" v-if="uncompletedWishes.length == 0">
            <v-alert elevation="2" dense type="info" color="primary">
                Sie haben keine Wünsche mehr, die Sie nicht bereits erledigt haben.<br>
                <a class="white--text text-decoration-underline" @click="$router.push('/wishes')">Schauen Sie sich die offenen Wünsche an</a>
            </v-alert>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-for="(wish, key) in uncompletedWishes" :key="'uncompleted_' + key">
            <WishItem :wish="wish"></WishItem>
        </v-col>
        <v-col cols="12">
            <v-subheader class="px-0">Erledigte Wünsche</v-subheader>
        </v-col>
        <v-col cols="12" v-if="completedWishes.length == 0">
            <v-alert elevation="2" dense type="info" color="primary">
                Sie haben bisher noch keine erledigten Wünsche
            </v-alert>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-for="(wish, key) in completedWishes" :key="'completed_' + key">
            <WishItem :wish="wish"></WishItem>
        </v-col>
    </v-row>
</DenseContainer>
</template>
<script>
import WishItem from '@/components/Items/WishItem';
import SubmitWishes from '@/components/Dialogs/SubmitWishes';
import Considered from '@/components/Dialogs/Considered';
export default {
    data() {
        return {
            dialogs: {
                submitWishes: 0,
                considered: 0
            }
        }
    },
    mounted() {
        if(this.$store.state.config.campaign.started !== true && !this.$store.state.auth.userClaims.access) {
            this.$router.push("/campaign");
            return;
        }
        this.$store.dispatch("attachTakenListener");
    },
    computed: {
        completedWishes() {
            var wishes = [];
            for(var key in this.$store.state.takenWishes) {
                if(this.$store.state.takenWishes[key].completed == true) {
                    wishes[wishes.length] = this.$store.state.takenWishes[key];
                }
            }
            return wishes;
        },
        uncompletedWishes() {
            var wishes = [];
            for(var key in this.$store.state.takenWishes) {
                if(this.$store.state.takenWishes[key].completed != true) {
                    wishes[wishes.length] = this.$store.state.takenWishes[key];
                }
            }
            return wishes;
        }
    },
    components: {
        WishItem,
        SubmitWishes,
        Considered
    }
}
</script>