<template>
<v-dialog v-model="showAlert" width="500" persistent>
    <v-card :loading="$store.state.isLoading">
        <v-card-title>Geschenkabgabe</v-card-title>
        <v-card-text v-if="!editMode" v-html="submitWishes"></v-card-text>
        <v-card-text v-else>
            <v-textarea v-model="submitWishes" outlined auto-grow></v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="!editMode && $store.state.auth.userClaims.campaign" text icon @click="edit"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn v-else-if="$store.state.auth.userClaims.campaign" text @click="showAlert = false">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="!editMode" text @click="showAlert = false">OK</v-btn>
            <v-btn v-else text @click="save">Speichern</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { collection, doc, updateDoc } from '@firebase/firestore';
import { firestore } from '../../firebase';
export default {
    data() {
        return {
            submitWishes: "",
            editMode: false,
            showAlert: false
        }
    },
    methods: {
        edit() {
            this.submitWishes = this.submitWishes.replace(/<br>/g, "\n");
            this.editMode = true;
        },
        save() {
            this.$store.state.isLoading = true;
            updateDoc(doc(collection(firestore, "configuration"), "campaign"), {
                submitWishes: this.submitWishes.replace(/(?:\r\n|\r|\n)/g, '<br>')
            }).then(() => {
                location.reload();
            }).catch(error => {
                this.alert.title = "Fehler";
                this.alert.message = "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut!";
                this.alert.active++;
            });
        }
    },
    watch: {
        active() {
            this.showAlert = true;
            this.editMode = false;
            this.submitWishes = this.$store.state.config.campaign.submitWishes;
        }
    },
    props: {
        active: Number
    }
}
</script>